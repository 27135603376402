<template>
    <div class="Part">
        <el-row :gutter="10" style="padding-left: 10px">
            <el-button type="primary" size="mini" @click="puttags">打标签</el-button>
            <el-button type="primary" size="mini" @click="_syncOneInfo">同步选中粉丝信息</el-button>
            <el-button type="primary" size="mini" @click="N_syncAllInfo">同步全部粉丝信息</el-button>
            <el-button type="primary" size="mini" @click="istagadmin = true">标签管理</el-button>
        </el-row>
        <tags-admin
            v-if="istagadmin"
            :wxtaglist="wxTags"
            @refresh="getwxtag"
            @close="istagadmin = false"
            @add="getwxtag">
        </tags-admin>
        <el-form
            ref="form"
            :inline="true"
            :model="param"
            label-width="80px"
            size="mini">
            <el-form-item label="用户昵称">
                <el-input 
                    :clearable="true"
                    v-model="param.nickname" 
                    @input="chengquChange(1)">
                </el-input>
            </el-form-item>
            <el-form-item label="是否关注">
                <el-select 
                    :clearable="true"
                    v-model="param.subscribe" 
                    @change="chengquChange(1)">
                    <el-option label="未关注" :value="0"></el-option>
                    <el-option label="关注" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标签">
                <el-select v-model="param.tagId" 
                    :clearable="true"
                    placeholder="请选择" 
                    @change="chengquChange(1)">
                    <el-option
                        v-for="item in wxTags"
                        :key="item.value"
                        :label="item.tagName"
                        :value="item.tagId">
                        <span style="float: left">{{ item.tagName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{item.fansNum}}</span>
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <el-table
            ref="filterTable"
            @cell-click="cellclick"
            @row-dblclick="lookfans"
            :data="records"
            :height="tableHeight"
            style="width: 100%">
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column prop="headimgurl" label="用户头像" width="180" align="center">
                <template slot-scope="scope">
                    <el-avatar
                        shape="square"
                        size="small"
                        :src="scope.row.headimgurl"
                    ></el-avatar>
                </template>
            </el-table-column>
            <el-table-column prop="nickname"
                align="center" 
                label="用户昵称" width="180" :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="unionid" label="用户唯一id" 
            align="center"
            width="90">
            </el-table-column> -->
            <el-table-column prop="tagid_list" 
                align="center"
                label="所在标签">
                <template slot-scope="scope">
                    <div v-if="scope.row.tagName_list[0]" style="display:inline-block">
                        <el-tag type="primary"
                            size="mini"
                            v-for="(item,index) in showthree(scope.row.tagName_list)" 
                            :key="index">
                            {{ item}}
                        </el-tag>
                        <span 
                            v-if="scope.row.tagid_list.length > 3">
                            共{{scope.row.tagid_list.length}}个
                        </span>
                    </div>
                    <el-tag 
                        v-else
                        type="primary" 
                        size="mini">
                        {{ "无标签" }}
                    </el-tag>
                    <i class="el-icon-edit" @click="tagshow(scope.row)"></i>
                </template>
            </el-table-column>
            <el-table-column prop="subscribe_time" align="center" label="用户关注时间">
                <template slot-scope="scope">
                    {{ scope.row.subscribe_time | gettime }}
                </template>
            </el-table-column>
        </el-table>
        <!-- 标签修改dialog -->
        <put-tags 
            v-if="dialogVisible" 
            :check="checkedCities"
            @close="dialogVisible = false" 
            @save="saveFanstag">
        </put-tags>
        <put-tags 
            v-if="isputtags" 
            @close="isputtags = false" 
            @save="_setwxtag">
        </put-tags>
        <el-dialog
            :close-on-click-modal="false"
            :modal-append-to-body='false'
            title="用户详情" 
            :visible.sync="showdetail" width="500px">
            <el-card class="box-card">
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-avatar shape="square" :size="50" :src="fansdetail.headimgurl"></el-avatar>
                    </el-col>
                    <el-col :span="18">
                        <p>会员名：<span>{{fansdetail.nickname}}</span></p>
                        <p>粉丝编号：<span>{{fansdetail.openid}}</span></p>
                        <p class="two">
                            <span>状态：<span>{{fansdetail.subscribe|subs}}</span></span>
                            <span>关注时间：<span>{{fansdetail.subscribe_time|gettime}}</span></span>
                        </p>
                        <p>渠道来源：<span>{{fansdetail.subscribe_scene|scene}}</span></p>
                    </el-col>
                </el-row>
            </el-card>
            <div slot="footer">
                <el-button @click="showdetail = false">取 消</el-button>
                <el-button type="primary" @click="showdetail = false">确 定</el-button>
            </div>
        </el-dialog>
        <el-pagination
            @size-change="_getwxfangs"
            :total="param.total"
            :page-count="param.pages"
            :page-size="param.limit"
            :page-sizes="[5,10,20,40,100]"
            :current-page="param.cursor"
            @current-change="chengquChange"
            align="center"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
    </div>
</template>

<script>
const TYPE = {
    ADD_SCENE_SEARCH:"公众号搜索",
    ADD_SCENE_ACCOUNT_MIGRATION:"公众号迁移",
    ADD_SCENE_PROFILE_CARD:"名片分享",
    ADD_SCENE_QR_CODE:"扫描二维码",
    ADD_SCENE_PROFILE_LINK:"图文页内名称点击",
    ADD_SCENE_PROFILE_ITEM :"图文页右上角菜单",
    ADD_SCENE_PAID:"支付后关注",
    ADD_SCENE_WECHAT_ADVERTISEMENT:"微信广告",
    ADD_SCENE_OTHERS:"其他"
}
import moment from "moment";
import TagsAdmin from './components/TagsAdmin'
import PutTags from './components/puttag'
import { mapActions, mapState } from "vuex";
import { getwxFans,setfanstag,getfansdetail,setwxtag,syncAllInfo,syncOneInfo } from "./api";
export default {
    data() {
        return {
            param: {
                cursor: 1, //当前页面
                limit: 10, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
                subscribe:1
            },
            istagadmin:false,
            records: [],
            checkedCities: [],
            fansid:null,
            dialogVisible: false,
            showdetail:false,
            isputtags:false,
            fansdetail:{},
            tableHeight: window.innerHeight - 225,
        };
    },
    created() {
        this._getwxfangs();
        this.getwxtag();
        // console.log(getwxFans)
    },
    computed: {
        ...mapState(["wxTags"]),
    },
    components:{
        PutTags,
        TagsAdmin
    },
    // 过滤器
    filters: {
        // 时间戳转日期格式
        gettime: function(value) {
            if (!value) return "";
            return moment(value, "X").format("YYYY-MM-DD HH:mm");
        },
        subs:function(val) {
            return val?'已关注':"未关注"
        },
        scene:function(val) {
            return TYPE[val]
        }
    },
    methods: {
        ...mapActions(["getwxtag"]),
        _setwxtag(item) {
            console.log(this.$refs.filterTable.selection)
            let list = this.$refs.filterTable.selection
            let ids = list.map(item => {
                return item.fanId
            })
            setwxtag({fanIdList:ids,tagsList:item}).then(res => {
                console.log(item)
            })
        },
        puttags() {
            let list = this.$refs.filterTable.selection
            if(list.length === 0) return this.$message({
                message: '请选择用户',
                type: 'warning'
            });
            this.isputtags = true
        },
        _syncOneInfo() {
            let list = this.$refs.filterTable.selection
            if(list.length === 0) return this.$message({
                message: '请选择用户',
                type: 'warning'
            });
            let ids = list.map(item => {
                return item.openid
            })
            console.log(ids)
            syncOneInfo({openidList:ids}).then(res => {
                this.$refs.filterTable.clearSelection();
                this._getwxfangs();
                this.getwxtag();
                this.$message({
                    type:"success",
                    message:'操作成功'
                })
                console.log(res)
            })
        },
        N_syncAllInfo() {
            console.log(322)
            syncAllInfo().then(res => {
                this._getwxfangs();
                this.getwxtag();
                this.$message({
                    type:"success",
                    message:'操作成功'
                })
                console.log(res)
            })
        },
        showthree(list) {
            if(list.length > 3) {
                return list.splice(3)
            }
            return list
        },
        lookfans({fanId}) {
            getfansdetail(fanId).then(res => {
                console.log(res.data.result)
                this.fansdetail = res.data.result
                this.showdetail = true
            })
        },
        saveFanstag(checkedCities) {
            let data = {fanIdList:[this.fansid],tagsList:checkedCities}
            setfanstag(data).then(res => {
                this.dialogVisible = false
                this._getwxfangs()
                this.getwxtag()
                this.$message({
                    type:"success",
                    message:'修改成功'
                })
            }).catch(err => {
                this.dialogVisible = false
                this.isError(err,this)
            })
        },
        tagshow(item) {
            this.checkedCities = item.tagid_list;
            this.fansid = item.fanId
            this.dialogVisible = true;
        },
        cellclick(item) {
            // console.log(item, item.fanId);
        },
        chengquChange(cursor) {
            this.param.cursor = cursor;
            this._getwxfangs();
        },
        _getwxfangs(size) {
            console.log(size)
            if(size) {
                this.param.limit = size
            }
            getwxFans(this.param).then((res) => {
                this.records = res.data.result.records;
                this.param.total = res.data.result.total;
                this.param.current = res.data.result.current;
                this.param.pages = res.data.result.pages;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/* .box{
  padding-top: 20px;
} */
p{
    margin:0;
    padding: 0;
}
.two{
    display: flex;
    justify-content: space-between;
}
.box-card{
    font-size: 14px;
    line-height: 24px;
}
</style>
