<template>
    <el-dialog
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        title="修改标签"
        :before-close="concel"
        :visible.sync="dialogVisible"
        width="500px">
        <el-checkbox-group v-model="checkedCities" :max="20">
            <el-checkbox v-for="tag in wxTags" :label="tag.tagId" :key="tag.tagId">{{tag.tagName}}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer">
            <el-button @click="concel">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            dialogVisible: true,
            checkedCities: this.check,
        };
    },
    props: {
        check: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        ...mapState(["wxTags"]),
    },
    methods: {
        save() {
            this.$emit("save", this.checkedCities);
            this.concel();
        },
        concel() {
            this.$emit("close");
        },
    },
};
</script>

<style>
</style>