import request from '@/utils/request'

export const getwxFans = (params) => {
    return request({
        url: '/common3/wxFans',
        method: 'get',
        params
    })
}

export const getwxtag = () => {
    return request({
        url: '/common3/wxTags/list',
        method: 'get',
    })
}

export const setfanstag = (data) => {
    return request({
        url: '/common3/wxTagsFans/binding',
        method: 'post',
        data
    })
}
// 单条查询微信粉丝信息
export const getfansdetail = (fanId) => {
    return request({
        url: `/common3/wxFans/${fanId}`,
        method: 'get'
    })
}
// 同步选中粉丝信息包含标签（同步云）
export const syncOneInfo = (data) => {
    return request({
        url: '/common3/wxFans/syncOneInfo',
        method: 'post',
        data: JSON.stringify(data)
    })
}
// 同步所有粉丝信息包含标签（同步云）
export const syncAllInfo = () => {
    return request({
        url: '/common3/wxFans/syncAllInfo',
        method: 'post'
    })
}
// 粉丝批量设置多标签（同步云）
export const setwxtag = (data) => {
    return request({
        url: '/common3/wxTagsFans/binding',
        method: 'post',
        data
    })
}
// 分页默认/个性化查询菜单
export const getwxMenus = (params) => {
    return request({
        url: '/common3/wxMenus',
        method: 'get',
        params
    })
}

export const setwxMenus = (data) => {
    return request({
        url: '/common3/wxMenus',
        method: 'post',
        data
    })
}

export const openjwm = (jwmId) => {
    return request({
        url: `/common3/wxMenus/${jwmId}/enable`,
        method: 'post'
    })
}

export const chargemenu = ({ jwmId, data }) => {
    return request({
        url: `/common3/wxMenus/${jwmId}`,
        method: 'put',
        data
    })
}

// 停用个性化菜单（同步云）
export const stopMenu = (jwmId) => {
    console.log(jwmId)
    return request({
        url: `/common3/wxMenus/${jwmId}/delConditional`,
        method: 'post'
    })
}
// 删除标签（粉丝数不超过10W）（同步云）
export const deltag = (tagId) => {
    return request({
        url: `/common3/wxTags/${tagId}`,
        method: 'DELETE'
    })
}

export const addtags = (params) => {
    return request({
        url: '/common3/wxTags',
        method: "post",
        params
    })
}

export const edittag = ({ tagId, tagName }) => {
    return request({
        url: `/common3/wxTags/${tagId}`,
        method: 'put',
        params: {
            tagName
        }
    })
}
// 同步已创建标签（同步云)
export const syncData = () => {
    return request({
        url: '/common3/wxTags/syncData',
        method: 'post'
    })
}

export const closeall = () => {
    return request({
        url: '/common3/wxMenus/disableAll',
        method: 'post'
    })
}