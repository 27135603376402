<template>
    <el-dialog
        title="标签管理" 
        :visible.sync="istagadmin" 
        :close-on-click-modal="false"
        :modal-append-to-body='false'
        :before-close="concel">
        <el-button type="primary" @click="istag = true">添加标签</el-button>
        <el-button type="primary" @click="_syncData">同步标签</el-button>
        <el-button type="primary" @click="_closeall">停用所有菜单</el-button>
        <el-table :data="wxtaglist" style="width: 100%" @row-dblclick="editor" height="500px">
            <el-table-column prop="tagName" label="标签名"> </el-table-column>
            <el-table-column prop="tagId" label="编号"> </el-table-column>
            <el-table-column prop="fansNum" label="人数"> </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button 
                        @click="del(scope.row)"
                        type="danger" 
                        size="mini">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            title="标签设置"
            append-to-body 
            width="500px"
            @close="form = {}"
            :visible.sync="istag" 
            :close-on-click-modal="false"
            :modal-append-to-body='false'>
            <el-form ref="form" :model="form" label-width="80px" inline>
                <el-form-item label="标签名">
                    <el-input v-model="form.tagName"></el-input>
                </el-form-item>
                <el-button type="primary" 
                    @click="addtag"
                    style="padding-left:10px">确定
                </el-button>
            </el-form>
        </el-dialog>
    </el-dialog>
</template>

<script>
import {deltag,addtags,edittag,syncData,closeall} from '../api/index'
export default {
    props:{
        wxtaglist:{
            type:Array
        }
    },
    data () {
        return {
            istagadmin:true,
            istag:false,
            form:{
                tagName:''
            }
        }
    },
    methods:{
        _closeall() {
            closeall().then(res => {
                this.$message({
                    type:"success",
                    message:'操作成功'
                })
            })
        },
        _syncData() {
            syncData().then(res => {
                this.$message({
                    type:"success",
                    message:'同步成功'
                })
            })
        },
        editor(item) {
            console.log(item)
            this.istag = true
            this.form = Object.assign({},item)
        },
        concel(done) {
            // done()
            this.$emit('close')
        },
        addtag() {
            console.log(this.form)
            if(this.form.tagId) {
                edittag({ tagId:this.form.tagId, tagName:this.form.tagName}).then(res => {
                    this.istag = false
                    this.$emit('add')
                    this.$message({
                        type:"success",
                        message:'修改成功'
                    })
                })
            }else{
                addtags({tagName:this.form.tagName}).then(res => {
                    this.istag = false
                    this.$emit('add')
                    this.$message({
                        type:"success",
                        message:'添加成功'
                    })
                })
            }
        
        },
        del(item) {
            deltag(item.tagId).then(res => {
                this.$emit('refresh')
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        },
    },
};
</script>

<style></style>
